/* eslint-disable import/no-unused-modules */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Helmet from '../components/Helmet';
import { H1, P } from '../components/Text';
import { PageLayout } from '../shared/layouts';
import withI18n from '../hocs/withI18n';
import { BIZ_STATUS_PAGE } from '../config';

export const Head = withI18n(() => {
  return <Helmet />;
});

const Status = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.location.href = BIZ_STATUS_PAGE;
  }, []);

  return (
    <PageLayout>
      <PageContainer>
        <H1>{t('status_page.title')}</H1>
        <P>{t('status_page.subtitle')}</P>
      </PageContainer>
    </PageLayout>
  );
};

export default Status;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const PageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 0;
  h1,
  p {
    text-align: center;
  }
`;
